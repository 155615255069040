import React, { useCallback } from 'react';
import { useRequest } from 'ahooks';
import Bar from '@/components/Bar'
import Panel from '@/components/Panel'
import { Spin } from 'antd';
import { GetBlindSpotData,GetBlindSpotRoadData,GetDifficultRoadOrRoad ,GetBlindSpotDataV2} from '@/services/api';
import style from './style.module.scss'
import IconPointGrid from '@/assets/img/icon_point_grid.png'
import { RingProgress } from '@ant-design/charts';
import ImgSource from '@/assets/img/tingchenan/blind_spot.png'
const ParkingBlindSpotLz: React.FC<{
  areaCode?: string;
}> = ({ areaCode }) => {
  const { data: blindData, loading: blindLoading, error: blindError } = useRequest(useCallback(() => {
    // return GetBlindSpotData(areaCode);
    return GetBlindSpotDataV2(areaCode);
  }, [areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })
  const { data:roadData=[] } = useRequest(useCallback(() => {
    return GetDifficultRoadOrRoad({
      areaCode:areaCode,
      limit:'3'
    })},[areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })


  if (blindError) {
    return (
      <Panel title="停车盲点" >
        <div className="fetch_container" style={{ height: 200 }}>
          请求错误
        </div>
      </Panel>
    )
  }
  const colors = [['#20BCFC', ''], ['#16FA1D', '#D8F8E2'], ['#FCEE0F', '#FFFFD9']];
  return (
    <Panel title="停车盲点">
      <div className={style.container}>
        {
          blindLoading ?
          <div className="fetch_container" style={{ height: 122 }}>
            <Spin/>
          </div> :
          <div className={style.head}>

            <div className={style.total}>
              <div className={style.icon}>
                <img src={IconPointGrid} alt=""/>
              </div>
              <div>
              <div className={style.number}>
              
                <span className={style.unit}>{blindData?blindData.parkingBlindSpotNo:0}个</span>
                
              </div>
              <div className={style.label}>停车盲点数</div>
             </div>
            </div>

                <div className={style.total}>
                
                <div>
                <div className={style.number}>
                
                  <span className={style.unit}>{blindData?blindData.parkingDemand:0}次</span>
                  
                </div>
                <div className={style.label}>引流次数</div>
               </div>
              </div>
              <div className={style.total}>
                
                <div>
                <div className={style.number}>
                
                  <span className={style.unit}>{blindData?blindData.supplyNo:0}个车位</span>
                  
                </div>
                <div className={style.label}>可供车位数</div>
               </div>
              </div>
             
            
          </div>
        }
        {
        !roadData||roadData.length<1 ?
            <div className="fetch_container" style={{ height: 132 }}>
              暂无数据
            </div> :
            <div className={style.charts}>
              {
                 roadData.map((item: { useRate: number; roadSectionName: React.ReactNode; }, index: React.ReactText) => (

                  <div className={style.item} key={`chart_${index}_dhhi`}>

                    {
                      <div >
                        <RingProgress
                          height={90}
                          width={90}
                          autoFit={false}
                          percent={item.useRate/100}
                          
                          color={colors[index]}//颜色值#16FA1D 0%, #15FD1 
                          innerRadius={0.85}
                          radius={0.98}
                          statistic={{
                            title: {
                              style: {
                                color: '#fff',
                                fontSize: '12px',
                                lineHeight: '14px',
                              },
                              formatter: function formatter() {
                                return '利用率';
                              },
                            },
                            content: {
                              style: {
                                color: '#fff',
                                fontSize: '12px',
                              }
                            }
                          }}
                        />
                      </div>

                    } <div className={style.item_name}> {item.roadSectionName}</div>
                  </div>

                ))


              }
            </div>
        }
      </div>
    </Panel>
  )
}

export default ParkingBlindSpotLz