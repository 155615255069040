import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useRequest, useInterval } from 'ahooks';
import Panel from '@/components/Panel';
import CustomTable from '@/components/CustomTable';
import { Spin, Button, Input } from 'antd';
import { GetGrovernRequireData, GetGrovernRequireDataV2 } from '@/services/api';
import ImgSource from '@/assets/img/tingchenan/tingchexuqiu.png'
export interface ParkingDemandlProps {
  areaCode?: string
 
}

const $ = (selector: string) => document.querySelector(selector);

const ParkingDemandl:React.FC<ParkingDemandlProps> = ({areaCode}) => {
  const [scrollInterval, setScrollInterval] = useState<number | null>(null);
  const [allowRefresh,setAllowRefresh] = useState(true);
  

  const { data = [], loading, error } = useRequest(useCallback(() => {
    // return GetGrovernRequireData(areaCode,'15');
    return GetGrovernRequireDataV2(areaCode,'15');
  },[areaCode]), {
    formatResult: res => res.data&&res.data.map((item,index) => ({
      ...item,
      index
    })),
    refreshDeps: [areaCode]
  })

  const scrollContainer = useCallback(() => {
    const container = $('.park-in--demand-table .ant-table-body');
    if (container !== null) {
      const { clientHeight, scrollHeight, scrollTop } = container;
      let height = scrollTop + 1;
      const top = scrollHeight - clientHeight - height;
      if (top <= 0) {
        height = 0
      }

      if (scrollHeight <= clientHeight) {
        setScrollInterval(null)
      }
      
      container.scrollTo(0,height)
    }
  }, [])

  const stopTick = useCallback(() => {
    setAllowRefresh(false);
    setScrollInterval(null)
  }, [])
  
  const startTick = useCallback(() => {
    setAllowRefresh(true);
    setScrollInterval(100)
  }, [])

  useInterval(() => {
    scrollContainer()
  }, scrollInterval)

  useEffect(() => {
    const container = $('.park-in--demand-table .ant-table-body')
    if (container) {
      const { clientHeight, scrollHeight } = container;
      const isOver = scrollHeight > clientHeight
      if (isOver) {
        setScrollInterval(100)
      }
    }
  }, [data])

  if (loading) {
    return (
      <Panel
        title="停车供需分析"
       
      >
        <div className="fetch_container" style={{ height: 200 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if (error) {
    return (
      <Panel title="停车供需分析">
        <div className="fetch_container" style={{ height: 200 }}>
          请求错误
        </div>
      </Panel>
    )
  }

  return (
    <Panel
      title="停车供需分析"
     
    >
      {/* <div onMouseEnter={stopTick} onMouseLeave={startTick}> */}
      <div>
        <CustomTable
          className="park-in--demand-table"
          dataSource={data}
          rowKey="orderNo"
          style={{height: '196px'}}
          columns={[
           
            { title: '区域',  dataIndex: 'areaName' },
            { title: '路段名称',  dataIndex: 'roadSectionName',  },
            { title: '总车位数',  dataIndex: 'berthNo' },
            { title: '停车需求量',  dataIndex: 'parkingDemand' },
          ]}
           scroll={{ y:156 }}
        />
      </div>
    </Panel>
  )
}

export default ParkingDemandl;