import React, { useCallback, useEffect,useMemo, useRef, useState } from 'react'
import Panel from '../index'
import { Spin,Carousel,Empty  } from 'antd'
import ParkingAnimationIcon from '@/components/ParkingAnimationIcon'
import { GetDifficultGridData,GetDifficultRoadOrRoad,GetDifficultParkOrRoad } from '@/services/api';
import style from './style.module.scss'
import { useInterval, useRequest } from 'ahooks'
import ImgSource from '@/assets/img/tingchenan/tingchenanwangge_.png'
import Abutton from '@/components/Abutton'
import AlarmModal, { OnAlarmClick, AlarmType } from '@/components/AlarmModal';
/** 转地址 */
const iframeURL = "http://lz.dgjx0769.com/lz/#/UrbanIntegration/BasicDataManage/ParkingStopTab";
const BusyParking: React.FC<{
  areaCode?: string;
}> = ({areaCode},props) => {
  const tableRef = useRef<HTMLDivElement>(null)
  const [interval, changeInterval] = useState<number | null>(null)
  const [alarmModal,setAlarmModal] = useState(false);
  const [lotchangModal,setLotChangModal] = useState(true);
  const [tabNoModal,setTabNoModal] = useState(1);
  const alarmRef = useRef<{
    onAlarmClick: OnAlarmClick
  }>(null);
  const { data:fetchData=[] } = useRequest(useCallback(() => {
    return GetDifficultParkOrRoad({
      areaCode:areaCode,
      limit:'15'
    })},[areaCode]), {
    formatResult: res => res.data,
      
    refreshDeps: [areaCode]
  })
  const { data:roadData=[] , loading, error, refresh} = useRequest(useCallback(() => {
    return GetDifficultRoadOrRoad({
      areaCode:areaCode,
      limit:'15'
    })},[areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })
  function group(array:[], subGroupLength:number) {
    let index = 0;
    let newArray = [];
    while(index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
    }
    return newArray;
}

//路段难
 let roadgroupedArray =roadData? group(roadData, 5):[];
 let roadnewData= roadgroupedArray.map((item,index:number)=>{
  return [item.slice(0, 2), item.slice(2, 5)]
 })
//停车场
let fetchgroupedArray = fetchData?group(fetchData, 5):[];
let fetchnewData= fetchgroupedArray.map((item,index:number)=>{
 return [item.slice(0, 2), item.slice(2, 5)]
})

  const scrollContainer = useCallback(() => {
    if (tableRef.current) {
      const { height: containerHeight } = tableRef.current.getBoundingClientRect()
      const scrollHeight = tableRef.current.scrollHeight+50
      let height = tableRef.current.scrollTop + 1
      const top = scrollHeight - containerHeight - height
      if (top <= 0) {
        height = 0
      }

      if (scrollHeight <= containerHeight) {
        changeInterval(null)
      }
      
      tableRef.current.scrollTo({
        top: height,
        left: 0
      })
    }
  }, [])
//切换
let data=lotchangModal?roadnewData:fetchnewData
let dataes=lotchangModal?roadData?roadData:[]:fetchData?fetchData:[]
const showIframe = () => {
  setAlarmModal(false);
  props.onOpenWorkbill();
}

const showAlarm = (alarmType: number) => {
  setAlarmModal(true);
  alarmRef.current?.onAlarmClick(alarmType as AlarmType);
}
 const lotchangdata =  (val: boolean)=> {
  setLotChangModal(val);
  setTabNoModal(val?1:0)
}
  useInterval(() => {
    scrollContainer()
  }, interval)

  const stopTick = useCallback(() => {
    changeInterval(null)
  }, [])
  
  const startTick = useCallback(() => {
    changeInterval(100)
  }, [])

  useEffect(() => {
    if (tableRef.current) {
      const { height } = tableRef.current.getBoundingClientRect()
      const isOver = tableRef.current.scrollHeight > height+50
      if (isOver) {
        changeInterval(100)
      }
    }
  }, [])

  const AbuttonBox = useMemo(() => {
    return <Abutton iframeURL={iframeURL} text={''} />
  }, [])

  if(error) {
    return (
      <Panel title="停车难点分析" >
        <div className="fetch_container" style={{ height: 480 }}>
          请求错误
        </div>
      </Panel>
    )
  }

  return (
    <Panel
      title="停车难点分析"  
    >
     
      <div className={style.parkinglotttag} onClick={()=>lotchangdata(!lotchangModal)}> 切换</div>
        <div className={style.parkinglotmore}  onClick={()=>showAlarm(8)}> 更多</div>
      {data.length>0
      ?
      <div  style={{ height: 206 }}>
       
        <Carousel autoplay autoplaySpeed={10000} swipe swipeToSlide touchMove arrows>
       {
       data.map((page, pageindex) => { 
        return (
            <div key={`page_${pageindex}`}  className={style.container}>
              {
                page.map((group, groupIndex) => {
                  return (
                    <div key={`group_${groupIndex}`} className={style.row}>
                      {group.map((item:object,index:number) => {
                        return (
                          <div key={`parking_${index}`} className={style.parking_item}>
                      
                            <div className={style.label}>{item['roadSectionName']||item['parkName'] }</div>
                            <ParkingAnimationIcon/>
                            <div className={style.tips}>
                              <div>利用率: {item['useRate']}%</div>
                              {/* <div>一小时到达: {item['oneHourArrive']}</div> */}
                            </div>
                          </div>
                        )
                      }) }
                    </div>
                  )
                })
              }
            </div>
          )
        })}
        </Carousel>
        </div>
        :
        <div className="fetch_container" style={{ height: 206 }}>
           <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE} 
          description={
              <span className={style.nodata}>
                暂无数据
              </span>
            }
          />
        </div>
        }
        
        {
          
          <div className={style.table} >
            <div className={style.head}>
              <div className={style.item}>排行</div>
              <div className={`${style.item} ${style.flex1}` }>区域</div>
              <div className={`${style.item} ${style.flex2}`}>路段/停车场名称</div>
              <div className={`${style.item} ${style.flex1}`}>利用率</div>
              <div className={`${style.item} ${style.flex1}`}>一小时到达</div>
            </div>
            <div className={style.body} ref={tableRef}  onMouseEnter={stopTick} onMouseLeave={startTick}>
              {
               
                dataes.map((item:object, i: any) =>
                <div className={style.row} key={`index_${i}`}>
                  <div className={style.item}>{`${i + 1}`}</div>
                  <div className={`${style.item} ${style.flex1}`}>{item['areaName']}</div>
                  <div className={`${style.item} ${style.flex2}`}>{item['roadSectionName']||item['parkName']}</div>
                  <div className={`${style.item} ${style.flex1}`}>{item['useRate']}</div>
                  <div className={`${style.item} ${style.flex1}`}>{item['oneHourArrive']}</div>
                </div>
                )
                
              }
            </div>
          </div>
        }
     
      <AlarmModal
        areaCode={areaCode}
        ref={alarmRef}
        visible={alarmModal}
        onShowIframe={showIframe}
        onClose={() => setAlarmModal(false)}
        onAlarmFocus={props.onAlarmFocus}
        tabNo={tabNoModal}
      />
    </Panel>
  )
}

export default BusyParking